<template>
  <div>
    <mly-header></mly-header>

    <search></search>

    <top3 :data="top3"></top3>

    <mly-game-list :game-list="game_list">
      <div class="foot" slot="append">
        <a href="javascript:void(0)" @click="moreGame">更多游戏&gt;&gt;</a>
      </div>
    </mly-game-list>
    <mly-placeholder></mly-placeholder>
    <mly-toolbar></mly-toolbar>
  </div>
</template>
<script>
import axios from "axios";
import util from '../../commons/util.js';


import search from "./search";
import top3 from "./top3";

export default {
  name: "game",
  components: {
    search,
    top3
  },
  mounted() {
    this.$nextTick(function() {
      this.pageNum += 1;
      axios
        .get("/game/listGame", {
          params: {
            pageNum: this.pageNum,
            pageRow: this.pageRow,
            operateSys: 2
          }
        })
        .then(response => {
          const list = response.returnData.list;
          if (list.length > 0) {
            this.top3 = list.splice(0, 3); //删除数组中的前三个元素，并返回
            this.game_list = list;
          }
        });
    });
  },
  data() {
    return {
      top3: [],
      pageNum: 0, //当前页
      pageRow: 10, //一页显示条数
      game_list: [],
    };
  },
  methods: {
    moreGame() {
      this.pageNum += 1;
      axios
        .get("/game/listGame", {
          params: {
            pageNum: this.pageNum,
            pageRow: this.pageRow,
            operateSys: 2
          }
        })
        .then(response => {
          const list = response.returnData.list;
          if (list.length > 0) {
            this.game_list.push(...list);
          } else {
            util.toast("没有更多了");
          }
        });
    }
  }
};
</script>
