<template>
  <form :class="$style.search" @submit.prevent="handleSubmit">
    <input type="search" placeholder="搜索游戏" v-model="keyword" maxlength="30">
    <a href="javascript:void(0)" @click="handleSubmit"></a>
  </form>
</template>

<script>
import axios from "axios";
import util from "../../commons/util.js";

export default {
  data() {
    return {
      keyword: ""
    };
  },
  methods: {
    handleSubmit() {
      if (this.keyword.trim().length > 0) {
        document.activeElement.blur();
        util.showLoading();
        axios
          .get("/game/listGame", {
            params: {
              name: this.keyword
            }
          })
          .then(response => {
            util.hideLoading();
            const list = response.returnData.list;
            if (list.length > 0) {
              sessionStorage.setItem(this.keyword, JSON.stringify(list));
              this.$router.push({
                path: `/game-search/${this.keyword}`
              });
            } else {
              util.toast("无相关游戏");
            }
          })
          .catch(error => {
            console.log(error);
            util.hideLoading();
          });
      }
    }
  }
};
</script>

<style module>
.search {
  border: 0.02rem solid #f5f5f5;
  border-radius: 0.1rem;
  margin: 0.2rem 0.25rem;
  display: flex;
  overflow: hidden;
}

.search input {
  width: 100%;
  height: auto;
  padding-top: 0.1rem;
  padding-bottom: 0.07rem;
  padding-left: 0.19rem;
  border: none;
  outline: none;
  font-size: 0.24rem;
}

.search a {
  display: block;
  background-color: #20a3dc;
  width: 1rem;
  background-image: url(@/assets/icon_search.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 0.28rem;
}
</style>
