<template>
  <section :class="$style.wrap">
    <router-link
      :to="'/mobile-games/detail/'+item.id"
      class="a-label-reset"
      :class="$style.item"
      v-for="item in data"
      :key="item.id"
    >
      <img :src="item.mediumImg" class="img-responsive" alt>
      <p>《{{item.name}}》</p>
    </router-link>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  }
};
</script>

<style module>
.wrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.2rem solid #f4f4f4;
  padding: 0.3rem 0.25rem;
  padding-top: 0;
}

.item {
  display: inline-block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item:hover,
.item:visited,
.item:link,
.item:active {
  text-decoration: none;
  color: #333;
}

.wrap img {
  margin: 0 auto;
  width: 2.5rem;
  height: 1.7rem;
}

.wrap p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.24rem;
  text-align: center;
  color: #333;
  margin-top: 0.2rem;
}
</style>
